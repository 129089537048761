<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">

                <div class="card elevation-5">
                    <div class="card-body">
                        <!-- <v-btn class="mt-3 mb-3" color="primary" elevation="2" small rounded @click="clearMarker()">CLEAR</v-btn> <br /> -->
                        <div class="row">
                            <div class="col-lg-3 col-12">
                                <v-radio-group v-model="rdType" dense v-on:change="resetAll()" row>
                                    <v-radio label="Sales Activity" color="info" value="sales_activity"></v-radio>
                                    <v-radio label="Customer Order" color="info" value="customer_order"></v-radio>
                                </v-radio-group>
                            </div>
                            <div class="col-lg-2 col-12">
                                <v-dialog ref="dialog" v-model="modalDateFrom" :return-value.sync="dateFrom" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateFrom" label="Start Period" readonly v-bind="attrs" v-on="on" dense prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateFrom" type="month" :show-current="true" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalDateFrom = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog.save(dateFrom)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-2 col-12">
                                <v-dialog ref="dialog2" v-model="modalDateTo" :return-value.sync="dateTo" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateTo" label="End Period" readonly v-bind="attrs" v-on="on" dense prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateTo" type="month" :show-current="true" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalDateTo = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog2.save(dateTo)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-5 col-12">
                                <span class="">Office : {{ stroffice }}</span> <br />
                                <span class="">Total Customer : {{ totalCustomer }}</span>
                            </div>
                            <div class="col-lg-12 col-12">
                                <div id="mapCanvas" class="myMapClass" style="height: 500px; width: 100%; border: 1px solid #213fad; border-radius: 10px;"></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <v-dialog v-model="dialogDetail" max-width="80%">
            <div class="card elevation-5">
                <div class="card-body">
                    Customer Name : {{ custName }}<br />
                    Customer Address : {{ custAddress }}<br />
                    Customer City : {{ custCity }}<br />
                </div>
            </div>
        </v-dialog>

    </div>
    
</template>

<script>



export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'E-Kencana',
                disabled: false,
                href: '/admin/kmb',
                },
                {
                text: 'Dashboard',
                disabled: false,
                href: '#',
                },
                {
                text: 'GIS Analysis',
                disabled: true,
                href: '#',
                },
            ],
            officeList: [],
            custList: [],
            orderList: [],
            dialogDetail: false,
            rdType: '',
            mymap: null,
            group1: L.featureGroup(),
            custName: '',
            custAddress: '',
            custCity: '',
            totalCustomer: '',
            modalDateFrom: false,
            dateFrom: '',
            modalDateTo: false,
            dateTo: '',
            minMonth: this.currentPeriod(),
            maxMonth: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7),
            stroffice: ''
        }
    },
    created() {
        
        

    },
    async mounted(){

        this.mymap = L.map('mapCanvas').setView([-7.515104417081626, 112.42708530581592], 6)
        
        var map = L.tileLayer(`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${process.env.TK_KMB}`, {
            maxZoom: 20,
            attribution: '<b>Viva Kencana Investindo</b><br> Mojokerto',
            id: 'mapbox/streets-v11',
            tileSize: 512,
            zoomOffset: -1
        }).addTo(this.mymap)

        this.initialize()

    },
    methods:{
        
        async initialize(){

            Swal.fire({
                text: "Please Wait...",
                allowOutsideClick: false,
                showCancelButton: false,
                showConfirmButton: false 
            })

            this.dateFrom =  (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7)
            // this.dateTo = this.currentPeriod2()
            this.dateTo = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7)
            
            await axios.get(`${process.env.VUE_APP_URL}/api/gbrk/GISAnalysis/getOffice`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
                })
                .then(res => {
                    
                    this.officeList = res.data.office
                    
                    for (var i = 0; i < this.officeList.length; i++) {
                        var marker = new L.marker([this.officeList[i]['lat'], this.officeList[i]['lng']])
                        .bindTooltip(this.officeList[i]['office'], 
                        {
                            permanent: true, 
                            direction: 'right'
                        })
                        .on('click', this.getDetailMap)
                        .addTo(this.mymap)

                    }

                    Swal.close()

                    this.$store.dispatch('setOverlay', false)

                    
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                Swal.close()
                console.log(err);

            })

        },
        
        async getDetailMap(e){
            
            var branchOffice = e.target._tooltip._content;
            this.stroffice = ''

            this.resetAll()

            if(this.rdType == 'customer_order'){

                this.resetAll()

                Swal.fire({
                    text: "Please Wait...",
                    allowOutsideClick: false,
                    showCancelButton: false,
                    showConfirmButton: false 
                })

                await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/GISAnalysis/getCustomerOrder`, { 
                    office: branchOffice,
                    dateFrom: new Date(this.dateFrom).toISOString().substr(0, 7).replace(/-/g,""),
                    dateTo: new Date(this.dateTo).toISOString().substr(0, 7).replace(/-/g,"")
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    if(this.mymap.hasLayer(this.group1)){
                        this.mymap.removeLayer(this.group1);
                        this.group1 = L.featureGroup()
                    }

                    this.orderList = res.data.result;
                    this.totalCustomer = res.data.total
                    this.stroffice = res.data.office

                    console.log(res.data.office);

                    for (var i = 0; i < res.data.result.length; i++) {

                        // var customer = this.orderList[i]['NamaCustomer']

                        L.circle([this.orderList[i]['sales_latitude'], this.orderList[i]['sales_longitude']], {
                            color: 'red',
                            fillColor: '#f03',
                            fillOpacity: 0.5,
                            radius: 10
                        }).addTo(this.group1).on("click", this.circleClick);

                    }

                    this.mymap.addLayer(this.group1)

                    Swal.close()
                    
                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    console.log(err);

                })

            } else if(this.rdType == 'sales_activity'){

                this.resetAll()

                Swal.fire({
                    text: "Please Wait...",
                    allowOutsideClick: false,
                    showCancelButton: false,
                    showConfirmButton: false 
                })

                await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/GISAnalysis/getSalesActivity`, { 
                    office: branchOffice,
                    dateFrom: new Date(this.dateFrom).toISOString().substr(0, 7).replace(/-/g,""),
                    dateTo: new Date(this.dateTo).toISOString().substr(0, 7).replace(/-/g,"")
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    if(this.mymap.hasLayer(this.group1)){
                        this.mymap.removeLayer(this.group1);
                        this.group1 = L.featureGroup()
                    }

                    this.orderList = res.data.result;
                    this.totalCustomer = res.data.total
                    this.stroffice = res.data.office

                    for (var i = 0; i < res.data.result.length; i++) {

                        L.circle([this.orderList[i]['sales_latitude'], this.orderList[i]['sales_longitude']], {
                            color: 'red',
                            fillColor: '#f03',
                            fillOpacity: 0.5,
                            radius: 10,
                            tesss: 'okeeee'
                        }).addTo(this.group1).on("click", this.circleClick);

                    }

                    this.mymap.addLayer(this.group1)

                    Swal.close()
                    
                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    console.log(err);

                })

            } else {

                Swal.fire({
                    icon: 'warning',
                    text: 'Please Select type'
                })

            }

        },

        async circleClick(e){

            // console.log(e.sourceTarget._latlng.lat);

            this.custName = ''
            this.custAddress = ''
            this.custCity = ''

            Swal.fire({
                text: "Please Wait...",
                allowOutsideClick: false,
                showCancelButton: false,
                showConfirmButton: false 
            })

            await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/GISAnalysis/getCustomerDetail`, { 
                lat: e.sourceTarget._latlng.lat,
                lng: e.sourceTarget._latlng.lng
            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                console.log(res.data.namacustomer)
                this.custName = res.data.namacustomer
                this.custAddress = res.data.alamat
                this.custCity = res.data.city
                // this.dialogDetail = true

                Swal.close()

                Swal.fire({
                    html: "Cust. Name : " + this.custName + "<br/>" + "Cust. Address : " + this.custAddress + "<br/>" + "Cust. City : " + this.custCity + "<br/>",
                    // icon: 'su',
                })
                
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                console.log(err);

            })

        },

        resetAll(){

            this.mymap.removeLayer(this.group1)
            this. group1 = L.featureGroup()

        },

        currentPeriod() {

            const current = new Date();
            current.setDate(current.getDate() - 40)

            const date = current.getFullYear() + '-' + ('0' + (current.getMonth())).slice(-2)
            return date;

        },

        currentPeriod2() {

            const current = new Date();
            current.setDate(current.getDate() + 20)

            const date = current.getFullYear() + '-' + ('0' + (current.getMonth())).slice(-2)
            return date;
            
        },

        dateStartOnChange(e){

            console.log(e);

        }

    },
    watch: {

        dateFrom: function() {
            this.resetAll()
        },

        dateTo: function() {
            this.resetAll()
        },
        
    }
    
}

</script>

<style scoped>

    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

    .modal {
        margin: 0 auto; 
    }

    .myMapClass {
        z-index: 2;
    }

</style>